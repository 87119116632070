<template>
  <div class="mt-button-wrapper">
    <slot></slot>
    <div class="flex-1"></div>
    <slot name="right"></slot>
  </div>
</template>

<script>
  export default {
    name: 'AmsButton'
  }
</script>

<style  lang="stylus" rel="stylesheet/stylus" scoped>

</style>
