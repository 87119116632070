<template>
  <div>
    <mt-button class="mb15">
      <el-button @click="onAddBtnClick" type="primary">新增</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-table
      ref="visitTable"
      :data="supplierList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      style="width: 100%;">
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="名称">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="number"
        label="编号">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="area"
        label="区域">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        width="300"
        prop="address"
        label="地址">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        width="300"
        prop="typeName"
        label="类型">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="100"
        label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="top">
            <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="supplierTotal"
      class="pagination">
    </el-pagination>

    <el-dialog :title="model.id === 0 ? '新增供应商' : '编辑供应商'" :visible.sync="dialogVisible" width="50%">
      <el-form :rules="rules" ref="dataForm" :model="model" label-position="left" label-width="100px" class="data-form"
               style='width: 500px; margin:0 auto;'>
        <el-form-item label="名称" prop="name">
          <el-input v-model="model.name"></el-input>
        </el-form-item>
        <el-form-item label="编号" prop="number">
          <el-input v-model="model.number"></el-input>
        </el-form-item>
        <el-form-item label="区域" prop="area">
          <el-input v-model="model.area"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="model.address"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-switch
            disabled
            :active-value="1"
            active-text="内部"
            :inactive-value="2"
            inactive-text="外部"
            inactive-color="#13ce66"
            v-model="model.type">
          </el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editSupplier">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import {apiGetSupplierList, apiEditSupplier, apiDeleteById} from '../../api/supplier'
  import {tableMixin} from '../../mixins/table'

  export default {
    name: 'TraceSupplier',
    mixins: [tableMixin],
    components: {MtButton},
    data() {
      return {
        supplierList: [],
        supplierTotal: 0,
        type: -1,
        dialogVisible: false,
        model: {
          id: 0,
          name: '',
          number: '',
          area: '',
          address: '',
          type: 2
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}],
          number: [{required: true, message: '不能为空', trigger: 'blur'}],
          area: [{required: true, message: '不能为空', trigger: 'blur'}],
          address: [{required: true, message: '不能为空', trigger: 'blur'}],
          type: [{required: true, message: '不能为空', trigger: 'change'}]
        }
      }
    },
    activated() {
      this._getSupplierList()
    },
    methods: {
      onAddBtnClick() {
        this.dialogVisible = true
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this._clearModel()
        this.dialogVisible = true
        this.model = Object.assign({}, this.model, row)
      },
      onRefreshBtnClick() {
        this._getSupplierList()
      },
      editSupplier() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            apiEditSupplier(this.model).then(({data}) => {
              this.pageIndex = 1
              this._getSupplierList()
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: this.model.id === 0 ? '添加成功' : '修改成功'
              })
              this._resetModel()
            })
          }
        })
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该供应商？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getSupplierList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getSupplierList()
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      },
      _resetModel() {
        this.model = {
          id: 0,
          name: '',
          number: '',
          area: '',
          address: '',
          type: 2
        }
      },
      _getSupplierList(type = this.type, pageIndex = this.pageIndex) {
        apiGetSupplierList(type, pageIndex).then(({data}) => {
          this.supplierList = data.data
          this.supplierTotal = data.total
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
