/**
 * Created by ZengFanhui on 2020/10/26 9:52
 */
export const PAGE_SIZE = 20

export const HTTP_TYPE = {
  GET: 'GET',
  POST: 'POST'
}

export const ROLE = {
  SUPERADMIN: 1,  // 超级管理员
  ADMIN: 2,       // 管理员
  USER: 3         // 普通用户
}

/**
 * 门店状态
 * @type {{OUT_OF_BUSINESS: number, DO_BUSINESS: number}}
 */
export const SALESROOM_STATUS = {
  OUT_OF_BUSINESS: 0,   // 歇业
  DO_BUSINESS: 1        // 营业
}

export const CONTENT_TYPE = {
  FORM: 'form'
}
