/**
 * Created by ZengFanhui on 2020/10/28 9:59
 */
import { PAGE_SIZE } from '../utils/constants'

/**
 * 表格相关mixin
 * @type {{data(): {paginationContainerHeight: number, filterContainerHeight: number, pageIndex: number, searchWrapperHeight: number, pageSize: number}, computed: {tableHeight(): number}, methods: {formatterIndex(*): *, formatterIndexNoPage(*): *}, updated(): void}}
 */
export const tableMixin = {
  data() {
    return {
      buttonWrapperHeight: 0,
      paginationContainerHeight: 0,
      searchWrapperHeight: 0,
      pageSize: PAGE_SIZE,
      pageIndex: 1
    }
  },
  mounted() {
    this.$nextTick(function () {
      let buttonWrapperHeightDom = document.querySelector('.mt-button-wrapper')
      if (buttonWrapperHeightDom) {
        this.buttonWrapperHeight = buttonWrapperHeightDom.offsetHeight
      }
      let paginationDom = document.getElementsByClassName('el-pagination')
      if (paginationDom.length !== 0) {
        let tempPaginationHeight = 0
        for (let i = 0; i < paginationDom.length; i++) {
          let pagination = paginationDom[i]
          if (pagination.offsetHeight >= tempPaginationHeight) {
            tempPaginationHeight = pagination.offsetHeight
          }
        }
        this.paginationContainerHeight = tempPaginationHeight
      }
      let searchWapperDom = document.querySelector('.mt-search-wrapper')
      if (searchWapperDom) {
        this.searchWrapperHeight = searchWapperDom.offsetHeight
      }
    })
  },
  computed: {
    tableHeight() {
      let clientHeight = document.documentElement.clientHeight
      let headerDom = document.querySelector('#header')
      let headerHeight = 0
      if (headerDom) {
        headerHeight = headerDom.offsetHeight
      }
      let tabsHeaderHeight = 0
      let tabsDom = document.querySelector('.el-tabs')
      if (tabsDom) {
        let tabHeader = tabsDom.querySelector('.el-tabs__header')
        tabsHeaderHeight = tabHeader.offsetHeight + 5
      }
      // console.log(clientHeight, headerHeight, this.buttonWrapperHeight, this.searchWrapperHeight, tabsHeaderHeight, this.paginationContainerHeight)
      let tableHeight = clientHeight - headerHeight - this.buttonWrapperHeight - this.paginationContainerHeight - this.searchWrapperHeight - tabsHeaderHeight - 65
      return tableHeight
    }
  },
  methods: {
    formatterIndex(index) {
      return (this.pageIndex - 1) * this.pageSize + index + 1
    },
    formatterIndexNoPage(index) {
      return index + 1
    }
  }
}
