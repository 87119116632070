/**
 * Created by ZengFanhui on 2021/1/4 15:44
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取供应商列表
 * @param type -1:全部
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetSupplierList (type = -1, pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    type,
    pageIndex,
    pageSize
  }
  return doHttp('/trace/supplier/list.action', HTTP_TYPE.GET, params)
}

/**
 * 编辑供应商
 * @param params
 * @returns {*}
 */
export function apiEditSupplier(params) {
  return doHttp('/trace/supplier/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/trace/supplier/delete.action', HTTP_TYPE.GET, params)
}
